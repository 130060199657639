/* eslint-disable prefer-destructuring */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Helmet } from "react-helmet";
import { Container, Media, Button, Row, Col, Badge, Form, Tab, Nav, Modal, Alert, Spinner, ButtonGroup, ButtonToolbar, Card, ListGroup } from "react-bootstrap";
import { injectStripe, Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IoMdCheckmark, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Icon from "./widgets/Icon";
import GenericShopOffcanvasBooking from "./GenericShopOffcanvasBooking";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/SessionActions";
import { contentStateData, changeContentSalesAttr } from "../actions/ContentActions";
import { shopStateData } from "../actions/ShopActions";
import ExpandCollapse from "../components/widgets/ExpandCollapse";
import * as Pages from "../utils/Pages";
import * as DataUtils from "../utils/DataUtils";
import * as ShopUtils from "../utils/ShopUtils";
import * as SalesUtils from "../utils/SalesUtils";
import * as DataValidations from "../utils/DataValidations";
import WebContext from "../utils/WebContext";

class GenericSaleLink extends React.Component {
    constructor( props ) {
        super( props );
        this.page = Pages.getPage( "salelink" );
        this.state = { isLoading: true };
        this.view = "default";
        this.trafficFrom = null;
        const tmpParams = new URLSearchParams( props.location.search );
        const tmpParamsKeys = Array.from( tmpParams.keys() );
        this.renderNotAvailableLink = this.renderNotAvailableLink.bind( this );
        this.renderSaleItems = this.renderSaleItems.bind( this );
        this.editBooking = this.editBooking.bind( this );
        this.saveSale = this.saveSale.bind( this );
        this.isCancelableBooking = this.isCancelableBooking.bind( this );
        this.renderProcessPaymentButtons = this.renderProcessPaymentButtons.bind( this );
        this.urlParams = {};
        this.lastRehydrate = null;
        for ( let i = 0; i < tmpParamsKeys.length; i += 1 ) {
            this.urlParams[ tmpParamsKeys[ i ] ] = tmpParams.get( tmpParamsKeys[ i ] );
            if ( tmpParamsKeys[ i ] === "view" ) {
                this.view = this.urlParams[ tmpParamsKeys[ i ] ];
            }
            if ( tmpParamsKeys[ i ] === "trafficFrom" ) {
                this.trafficFrom = this.urlParams[ tmpParamsKeys[ i ] ];
            }
        }
    }
    componentDidMount() {
        this.props.loadUIContext();
        const promises = [];
        promises.push( this.props.getCalendarBookedSlots() );
        promises.push( this.props.getCalendarTeamMembers() );
        promises.push( this.props.getCalendarServices() );
        promises.push( this.props.getCalendarBonos() );
        Promise.all( promises )
            .then( () => this.setState( { isLoading: false } ) );
    }
    validateForm() {
        const result = true;
        const {
            userName, userEmail, userMobile, userAddress, userCity, userPostalCode, userProvince
        } = this.props.pwSession.shopAttributes;
        return result;
    }
    saveSale() {
        this.props.saveCustomerSaleRequest();
    }
    isCancelableBooking( saleItem, booking ) {
        const saleItemBooking = saleItem.saleItemBookings.find( tmp => tmp.bookingId === booking.bookingId );
        const editedBooking = this.props.content.sales.saleBookings.find( tmp => tmp.bookingId === booking.bookingId );
        if ( saleItemBooking && saleItemBooking.bookingStatus === "canceled" ) {
            return false;
        }
        if ( editedBooking && editedBooking.bookingStatus === "canceled" ) {
            return false;
        }
        if ( saleItemBooking && saleItemBooking.bookingDatetime ) {
            const hoursBeforeBooking = DataUtils.dateDiff( DataUtils.now(), saleItemBooking.bookingDatetime, "hours" );
            if ( hoursBeforeBooking < this.props.content.sales.bookingCancelationMaxHours ) {
                return false;
            }
        }
        return true;
    }
    editBooking( saleItem, booking ) {
        if ( !this.isCancelableBooking( saleItem, booking ) ) {
            return;
        }
        this.setState( { isLoadingEditBooking: true } );
        // fetching optimisations
        let refreshResources = false;
        let millisToRefresh = 1000 * 60 * 60; // an hour to refresh services info
        if ( this.lastRehydrate === null || DataUtils.nowMillis() - this.lastRehydrate > millisToRefresh ) {
            refreshResources = true;
        }
        let fetchingStarts = DataUtils.nowMillis();
        // ************************

        this.props.getCalendarBookedSlots()
            .then( () => refreshResources ? this.props.getCalendarTeamMembers() : true )
            .then( () => refreshResources ? this.props.getCalendarServices() : true )
            .then( () => refreshResources ? this.props.getCalendarBonos() : true )
            .then( () => {
                let showOnlyBooking = null;
                if ( saleItem.saleItemType === "bono" ) {
                    showOnlyBooking = booking;
                }
                this.props.openShopBookingOffcanvas( "booking", { saleItem: Object.assign( {}, saleItem ), showOnlyBooking } );
                this.setState( { isLoadingEditBooking: false } );
                // fetching optimisations
                this.lastRehydrate = DataUtils.nowMillis();
                // ************************
            } )
            .catch( () => this.setState( { isLoadingEditBooking: false } ) );
    }
    cancelBooking( saleItem, booking ) {
        if ( !this.isCancelableBooking( saleItem, booking ) ) {
            return;
        }
        const newBooking = Object.assign( {}, booking, { bookingStatus: "canceled", bookingStatusInfo: "canceled_by_customer" } );
        let newBookings = [];
        if ( saleItem ) {
            newBookings = this.props.content.sales.saleBookings.filter( tmpArgBooking => tmpArgBooking.bookingId !== newBooking.bookingId );
        }
        newBookings.push( newBooking );
        this.props.changeContentSalesAttr( "saleBookings", newBookings );
    }
    confirmBooking( saleItem, booking ) {
        const newBooking = Object.assign( {}, booking, { bookingConfirmed: 1, bookingConfirmedDatetime: DataUtils.now() } );
        let newBookings = [];
        if ( saleItem ) {
            newBookings = this.props.content.sales.saleBookings.filter( tmpArgBooking => tmpArgBooking.bookingId !== newBooking.bookingId );
        }
        newBookings.push( newBooking );
        this.props.changeContentSalesAttr( "saleBookings", newBookings );
    }
    renderSaleItems ( filter ) {
        if ( !this.props.content.sales.current || !this.props.content.sales.current.saleItems ) {
            return null;
        }
        const sale = this.props.content.sales.current;
        let saleItems = sale.saleItems;
        if ( filter === "active" ) {
            saleItems = saleItems.filter( tmpSaleItem => tmpSaleItem.saleItemActive );
        } else if ( filter === "inactive" ) {
            saleItems = saleItems.filter( tmpSaleItem => !tmpSaleItem.saleItemActive );
        }
        if ( saleItems.length === 0 ) {
            return ( <div style={ { textAlign: "center" } }>No hay items para mostrar.</div> );
        }
        return saleItems.map( argItem => {
            const item = SalesUtils.applySaleItemOverrides( sale, argItem );
            let bono = null;
            if ( item.saleItemType === "bono" ) {
                bono = this.props.pwSession.shopAttributes.bonos.find( tmp => tmp.bonoId === item.saleItemBonoId );
            }
            // service total amount of sessions based in quantity
            const serviceTotalSessions = [];
            let renderSaleItemType = item.saleItemType;
            if ( renderSaleItemType === "link" ) {
                renderSaleItemType = item.saleItemLinkType;
            }
            const saleItemWithBonos = false;
            const saleItemSuggestedBonos = [];
            if ( renderSaleItemType === "service" ) {
                for ( let index = 1; index <= item.saleItemQuantity; index += 1 ) {
                    const sessionBookings = item.saleItemBookings.filter( tmpBooking => tmpBooking.bookingServiceId === item.saleItemServiceId && tmpBooking.bookingSessionNumber === index );
                    if ( sessionBookings.length > 0 ) {
                        sessionBookings.forEach( tmp => serviceTotalSessions.push( tmp ) );
                    } else {
                        serviceTotalSessions.push( { bookingSessionNumber: index } );
                    }
                }
            }
            return (
                <Alert variant="light" style={ { marginTop: "10px" } }>
                    <Alert.Heading>
                        <span> { item.saleItemQuantity } x <strong>{ item.saleItemTitle }</strong></span>
                    </Alert.Heading>
                    { renderSaleItemType === "service" && DataUtils.sortArray( serviceTotalSessions, { sortingKeys: [ { alias: "bookingDatetime", direction: "asc", nullsAtTheEnd: true } ] } ).map( tmpBooking => {
                        const editedBooking = this.props.content.sales.saleBookings.find( tmp => tmp.bookingId === tmpBooking.bookingId );
                        let editedBookingMember = null;
                        if ( editedBooking ) {
                            editedBookingMember = this.props.pwSession.shopAttributes.teamMembers.find( tmp => tmp.teamMemberId === editedBooking.bookingTeamMemberId );
                        }
                        if ( !tmpBooking.bookingDatetime ) {
                            return (
                                <Row className="pws_sale_booking_row">
                                    <Col xs="12">
                                        <p className="pws_manager_carddata">
                                            { editedBooking ? <span className={ `pw_sale_item_booking_edited${ editedBooking.bookingStatus === "canceled" ? " pw_sale_item_booking_canceled" : "" }` }><span className="pws_label">{ tmpBooking.bookingStatus === "canceled" ? <Badge bg="danger">Cancelada</Badge> : null } <strong>{ editedBooking.bookingDatetime }</strong></span> con <strong>{ editedBookingMember ? editedBookingMember.teamMemberFullName : null } { editedBooking.bookingStatus === "canceled" ? "(Marcada como cancelada)" : null }</strong></span> : null }
                                            { editedBooking ? <p>IMPORTANTE: Los cambios en esta cita sólo se confirmaran cuando hagas clic en el botón "CONFIRMAR CAMBIOS"</p> : null }
                                            { !editedBooking ? <span className="pws_label">{ tmpBooking.bookingStatus === "canceled" ? <Badge bg="danger">Cancelada</Badge> : null } <strong> sin cita</strong></span> : null }
                                            { sale.saleStatus !== "canceled" ?
                                                <ButtonGroup className="pw_sale_link_btn_group" style={ { float: "right" } }>
                                                    <Button variant="success" disabled={ this.state.isLoadingEditBooking } onClick={ () => this.editBooking( item, tmpBooking ) }>
                                                        { this.state.isLoadingEditBooking ? <Spinner as="span" animation="grow" role="status" aria-hidden="true" size="sm" /> : <Icon name="calendar" color="white" /> } Agendar cita
                                                    </Button>
                                                </ButtonGroup> : null }
                                        </p>
                                    </Col>
                                </Row>
                            );
                        }
                        const isToday = DataUtils.isSameDay( tmpBooking.bookingDatetime, DataUtils.today() );
                        const bookingMember = this.props.pwSession.shopAttributes.teamMembers.find( tmp => tmp.teamMemberId === tmpBooking.bookingTeamMemberId );
                        if ( renderSaleItemType === "bono" ) {
                            bono = this.props.pwSession.shopAttributes.bonos.find( tmp => tmp.bonoId === item.saleItemBonoId );
                            if ( bono && bono.bonoServices ) {
                                bono.bonoServices.forEach( tmpBonoService => {
                                    if ( tmpBonoService && tmpBonoService.bonoServiceServiceId ) {
                                        const fullBonoService = this.props.pwSession.shopAttributes.services.find( tmpService => tmpService.serviceId === tmpBonoService.bonoServiceServiceId );
                                    }
                                } );
                            }
                        }
                        let bookingClass = "";
                        if ( ( editedBooking && editedBooking.bookingStatus === "canceled" ) || ( tmpBooking && tmpBooking.bookingStatus === "canceled" ) ) {
                            bookingClass = "danger";
                        }
                        const confirmedBooking = ( editedBooking && editedBooking.bookingConfirmed ) || tmpBooking.bookingConfirmed;
                        return (
                            <Row className={ `pws_sale_booking_row${ bookingClass ? ` ${ bookingClass }` : "" }` }>
                                <Col xs="12">
                                    <p className="pws_manager_carddata">
                                        { !editedBooking ? <span><span className="pws_label">{ tmpBooking.bookingStatus === "canceled" ? <Badge bg="danger">Cancelada</Badge> : null }{ confirmedBooking ? <Badge bg="success"> Asistencia confirmada</Badge> : null } <strong>{ tmpBooking.bookingDatetime }</strong></span> con <strong>{ bookingMember ? bookingMember.teamMemberFullName : null }</strong></span> : null }
                                        { editedBooking ? <span className={ `pw_sale_item_booking_edited${ editedBooking.bookingStatus === "canceled" ? " pw_sale_item_booking_canceled" : "" }` }><span className="pws_label"><strong>{ editedBooking.bookingDatetime }</strong></span> con <strong>{ editedBookingMember ? editedBookingMember.teamMemberFullName : null } { editedBooking.bookingStatus === "canceled" ? "(Marcada como cancelada)" : null }</strong></span> : null }
                                        { editedBooking ? <p className="pw_salelink_changes_info">¡Atención! Los cambios en esta cita se confirmarán sólo cuando hagas clic en "CONFIRMAR CAMBIOS" al final de esta página</p> : null }
                                        { this.isCancelableBooking( item, tmpBooking ) ?
                                            <ButtonToolbar className="pw_sale_link_btn_group" style={ { float: "right" } }>
                                                { !tmpBooking.bookingPaid ?
                                                    <Button variant="dark" onClick={ () => this.cancelBooking( item, tmpBooking ) }>
                                                        <Icon name="cancel" color="white" /> Cancelar cita
                                                    </Button> : null }
                                                { !confirmedBooking ?
                                                    <Button variant="primary" onClick={ () => this.confirmBooking( item, tmpBooking ) }>
                                                        <Icon name="check" color="white" /> Confirmo asistencia
                                                    </Button> : null }
                                                <Button variant="success" disabled={ this.state.isLoadingEditBooking } onClick={ () => this.editBooking( item, tmpBooking ) }>
                                                    { this.state.isLoadingEditBooking ? <Spinner as="span" animation="grow" role="status" aria-hidden="true" /> : <Icon name="calendar" color="white" /> } Modificar cita
                                                </Button>
                                            </ButtonToolbar> : null }
                                    </p>
                                </Col>
                            </Row>
                        );
                    } ) }
                    { item.saleItemType === "bono" && bono && bono.bonoServices.map( tmpBonoService => {
                        const bonoTotalSessions = [];
                        for ( let index = 1; index <= tmpBonoService.bonoServiceTotalSessions; index += 1 ) {
                            const sessionBookings = item.saleItemBookings.filter( tmpBooking => tmpBooking.bookingBonoServiceId === tmpBonoService.bonoServiceId && tmpBooking.bookingSessionNumber === index );
                            if ( sessionBookings.length > 0 ) {
                                sessionBookings.forEach( tmp => bonoTotalSessions.push( tmp ) );
                            } else {
                                bonoTotalSessions.push( { bookingSessionNumber: index } );
                            }
                        }
                        return (
                            <Alert variant="light">
                                <Alert.Heading>{ tmpBonoService.bonoServiceService.serviceTitle }</Alert.Heading>
                                { DataUtils.sortArray( bonoTotalSessions, { sortingKeys: [ { alias: "bookingDatetime", direction: "asc", nullsAtTheEnd: true } ] } ).map( tmpBooking => {
                                    const editedBooking = this.props.content.sales.saleBookings.find( tmp => tmp.bookingId === tmpBooking.bookingId );
                                    let editedBookingMember = null;
                                    if ( editedBooking ) {
                                        editedBookingMember = this.props.pwSession.shopAttributes.teamMembers.find( tmp => tmp.teamMemberId === editedBooking.bookingTeamMemberId );
                                    }
                                    if ( !tmpBooking.bookingDatetime ) {
                                        return (
                                            <Row className="pws_sale_booking_row">
                                                <Col xs="12">
                                                    <p>
                                                        { editedBooking ? <span className={ `pw_sale_item_booking_edited${ editedBooking.bookingStatus === "canceled" ? " pw_sale_item_booking_canceled" : "" }` }><span className="pws_label"><strong>{ editedBooking.bookingDatetime }</strong></span> con <strong>{ editedBookingMember ? editedBookingMember.teamMemberFullName : null } { editedBooking.bookingStatus === "canceled" ? "(Marcada como cancelada)" : null }</strong></span> : null }
                                                        { editedBooking ? <p>IMPORTANTE: Los cambios en esta cita sólo se confirmaran cuando hagas clic en el botón "CONFIRMAR CAMBIOS"</p> : null }
                                                        { !editedBooking ? <span className="pws_label">{ tmpBooking.bookingStatus === "canceled" ? <Badge bg="danger">Cancelada</Badge> : null } <strong>sin cita</strong></span> : null }
                                                        { this.isCancelableBooking( item, tmpBooking ) ?
                                                            <ButtonGroup style={ { float: "right" } }>
                                                                <Button variant="success" disabled={ this.state.isLoadingEditBooking } onClick={ () => this.editBooking( item, tmpBooking ) }>
                                                                    { this.state.isLoadingEditBooking ? <Spinner as="span" animation="grow" role="status" aria-hidden="true" size="sm" /> : <Icon name="calendar" color="white" /> } Agendar cita
                                                                </Button>
                                                            </ButtonGroup> : null }
                                                    </p>
                                                </Col>
                                            </Row>
                                        );
                                    }
                                    const bookingMember = this.props.pwSession.shopAttributes.teamMembers.find( tmp => tmp.teamMemberId === tmpBooking.bookingTeamMemberId );
                                    const isToday = DataUtils.isSameDay( tmpBooking.bookingDatetime, DataUtils.today() );
                                    let bonoExchangedService = "";
                                    if ( tmpBonoService.bonoServiceService.serviceId !== tmpBooking.bookingServiceId ) {
                                        const bookingService = this.props.pwSession.shopAttributes.services.find( tmpService => tmpService.serviceId === tmpBooking.bookingServiceId );
                                        if ( bookingService ) {
                                            bonoExchangedService = bookingService.serviceTitle;
                                        }
                                    }
                                    return (
                                        <Row className="pws_sale_booking_row" style={ isToday ? this.todayBookingStyle : null }>
                                            <Col xs="12">
                                                <p>
                                                    { !editedBooking ? <span><span className="pws_label">{ tmpBooking.bookingStatus === "canceled" ? <Badge bg="danger">Cancelada</Badge> : null } <strong>{ tmpBooking.bookingDatetime }</strong></span> con <strong>{ bookingMember ? bookingMember.teamMemberFullName : null }</strong></span> : null }
                                                    { editedBooking ? <span className={ `pw_sale_item_booking_edited${ editedBooking.bookingStatus === "canceled" ? " pw_sale_item_booking_canceled" : "" }` }><span className="pws_label"><strong>{ editedBooking.bookingDatetime }</strong></span> con <strong>{ editedBookingMember ? editedBookingMember.teamMemberFullName : null } { editedBooking.bookingStatus === "canceled" ? "(Marcada como cancelada)" : null }</strong></span> : null }
                                                    { editedBooking ? <p>IMPORTANTE: Los cambios en esta cita sólo se confirmaran cuando hagas clic en el botón "CONFIRMAR CAMBIOS"</p> : null }
                                                    { this.isCancelableBooking( item, tmpBooking ) ?
                                                        <ButtonGroup style={ { float: "right" } }>
                                                            { !tmpBooking.bookingPaid ?
                                                                <Button size="sm" variant="dark" onClick={ () => this.cancelBooking( item, tmpBooking ) }>
                                                                    <Icon name="cancel" color="white" /> Cancelar cita
                                                                </Button> : null }
                                                            <Button size="sm" variant="success" disabled={ this.state.isLoadingEditBooking } onClick={ () => this.editBooking( item, tmpBooking ) }>
                                                                { this.state.isLoadingEditBooking ? <Spinner as="span" animation="grow" role="status" aria-hidden="true" size="sm" /> : <Icon name="calendar" color="white" /> } Modificar cita
                                                            </Button>
                                                        </ButtonGroup> : null }
                                                </p>
                                            </Col>
                                        </Row>
                                    );
                                } ) }
                            </Alert>
                        );
                    } ) }
                </Alert>
            );
        } );
    }
    renderNotAvailableLink( type ) {
        let title = "Link no disponible";
        let description = "El link al que intenta acceder no se encuentra disponible.";
        switch ( type ) {
            case "notexists": {
                title = "El link al que intenta acceder no es válido.";
                description = "Por favor, verifique que el link es correcto o solicite un nuevo link.";
                break;
            }
            case "inactive": {
                title = "El link de pago al que intenta acceder ya no está activo";
                description = "Por favor, solicite un nuevo link de pago.";
                break;
            }
            case "expired": {
                title = "El link de pago al que intenta acceder ha expirado";
                description = "Por favor, solicite un nuevo link de pago.";
                break;
            }
            default:
        }
        return (
            <div id="shop-cart" style={ { paddingTop: this.page.navBarCSSPosition === "fixed" && this.page.navBarPagePadding ? this.page.navBarPagePadding : 0 } }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ this.context.language } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ this.context.language } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                <Container>
                    <Alert variant="warning">
                        <Alert.Heading>{ title }</Alert.Heading>
                        <p>
                            { description }
                        </p>
                    </Alert>
                </Container>
            </div>
        );
    }
    renderProcessPaymentButtons() {
        // canceled sale
        if ( this.props.content.sales.current.saleStatus === "canceled" || this.props.content.sales.current.saleOutsourced ) {
            return null;
        }
        const processPaymentTotalAmount = this.props.content.sales.current.saleTotalAmount - this.props.content.sales.current.salePaidTotalAmount;
        // nothing to pay
        if ( processPaymentTotalAmount <= 0 ) {
            return null;
        }
        const redsysActive = this.props.shop.paymentMethods.redsys && this.props.shop.paymentMethods.redsys.active;
        const isPrepaymentAvailable = SalesUtils.isPrepaymentAvailable( this.props.content.sales.current, this.props.shop.prepaymentAmountForService );
        if ( redsysActive ) {
            return (
                <form name="redsysform" id="redsysform" action="https://sis-t.redsys.es" method="POST">
                    <input type="hidden" id="Ds_SignatureVersion" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
                    <input type="hidden" id="Ds_MerchantParameters" name="Ds_MerchantParameters" value="" />
                    <input type="hidden" id="Ds_Signature" name="Ds_Signature" value="" />
                    <ButtonToolbar className="pw_sale_link_btn_group">
                        { Pages.redsys && Pages.redsys.bizum && isPrepaymentAvailable ?
                            <span>
                                <Button
                                    variant="pw-purple"
                                    className="pw_btn_bizum"
                                    onClick={ ( e ) => {
                                        e.preventDefault();
                                        this.props.processSalePaymentRequest( null, { redsysPaymentMethod: "bizum", processPaymentTotalAmount: this.props.shop.prepaymentAmountForService, processPaymentMethod: "redsys" } );
                                    } }
                                >
                                    <Icon name="bizum" />
                                    Pagar sólo reserva con Bizum { DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.shop.prepaymentAmountForService ) }
                                </Button>
                                &nbsp;
                            </span> : null }
                        { Pages.redsys && Pages.redsys.bizum ?
                            <span>
                                <Button
                                    variant="success"
                                    className="pw_btn_bizum"
                                    onClick={ ( e ) => {
                                        e.preventDefault();
                                        this.props.processSalePaymentRequest( null, { redsysPaymentMethod: "bizum", processPaymentTotalAmount, processPaymentMethod: "redsys" } );
                                    } }
                                >
                                    <Icon name="bizum" />
                                    Pagar total con Bizum
                                </Button>
                                &nbsp;
                            </span> : null }
                        { isPrepaymentAvailable ?
                            <Button
                                variant="pw-purple"
                                onClick={ ( e ) => {
                                    e.preventDefault();
                                    this.props.processSalePaymentRequest( null, { processPaymentTotalAmount: this.props.shop.prepaymentAmountForService, processPaymentMethod: "redsys" } );
                                } }
                            >
                                Pagar sólo la reserva { DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.shop.prepaymentAmountForService ) }
                            </Button> : null }
                        <Button
                            variant="success"
                            onClick={ ( e ) => {
                                e.preventDefault();
                                this.props.processSalePaymentRequest( null, { processPaymentTotalAmount, processPaymentMethod: "redsys" } );
                            } }
                        >
                            Pagar el total
                        </Button>
                    </ButtonToolbar>
                </form>
            );
        }
        return null;
    }
    render() {
        if ( this.state.isLoading ) {
            return (
                <div className="pw_fullscreen_loading">
                    <div className="pw_fullscreen_loading_content">
                        <Spinner as="span" animation="grow" role="status" aria-hidden="true" size="sm" />
                        Descargando datos ...
                    </div>
                </div>
            );
        }
        let isLandscape = true;
        const lang = this.context.language;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        if ( !this.props.content.sales.current ) {
            return this.renderNotAvailableLink( "notexists" );
        }
        if ( !this.props.content.sales.current.saleActive ) {
            return this.renderNotAvailableLink( "inactive" );
        }
        const sale = this.props.content.sales.current;
        let saleHasChanged = false;
        if ( this.props.content.sales.saleBookings.length > 0 ) {
            saleHasChanged = true;
        }
        let saleHasCanceledItems = false;
        if ( sale && sale.saleItems ) {
            saleHasCanceledItems = sale.saleItems.find( tmp => !tmp.saleItemActive );
        }
        return (
            <div id="shop-cart" className={ Pages.getPageClassNames( this.page ) }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                <Container>
                    <h2 className="pw_section_title">Info de tu pedido</h2>
                    <h3 className="pw_section_subtitle">Ref: { sale.saleId }</h3>
                    <h3 className="pw_section_subtitle">Creado el día: { DataUtils.formatDateTimeNiceShort( sale.saleDatetimeCreation ) }</h3>
                    <h5 className="pw_section_subtitle">{ SalesUtils.getSaleBadges( sale, "web" ).map( badgeInfo => <span><Badge size="sm" bg={ badgeInfo.variant } text={ badgeInfo.textColor }>{ badgeInfo.text }</Badge> </span> ) }</h5>
                    <div className="pw_sale_link_box pw_sale_link_payments">
                        <h2 style={ { textAlign: "center" } }>Información de pago:</h2>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <b>Total del pedido:</b>
                                </div>
                                <Badge bg="light" text="dark">{ DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.content.sales.current.saleTotalAmount ) }</Badge>
                            </ListGroup.Item>
                            { this.props.content.sales.current.salePaidTotalAmount ?
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <b>Pagado:</b>
                                    </div>
                                    <Badge bg="light" text="dark">{ DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.content.sales.current.salePaidTotalAmount ) }</Badge>
                                </ListGroup.Item> : null }
                            <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <b>Pendiente de pago:</b>
                                </div>
                                <Badge bg="light" text="dark">{ DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.content.sales.current.saleTotalAmount - this.props.content.sales.current.salePaidTotalAmount ) }</Badge>
                            </ListGroup.Item>
                        </ListGroup>
                        { this.renderProcessPaymentButtons() }
                    </div>
                    <div className="pw_sale_link_box">
                        <h2 style={ { textAlign: "center" } }>Items de tu pedido:</h2>
                        { this.renderSaleItems( "active" ) }
                        <div>
                            <div style={ { clear: "both" } }>
                                <Container>
                                    <Row>
                                        <Col>
                                            { this.props.content.sales.isSavedSale ? <Alert variant="success">¡Super! Los cambios fueron realizados con éxito y nuestro equipo ha sido notificado. ¡Muchas gracias!</Alert> : null }
                                            { this.props.content.sales.errorMessage ? <Alert variant="danger">{ Pages.text( this.props.content.sales.errorMessage ) }</Alert> : null }
                                            { saleHasChanged ?
                                                <ButtonGroup style={ { float: "right" } }>
                                                    <Button
                                                        variant="dark"
                                                        onClick={ () => {
                                                            window.location.href = `${ Pages.getPage( "salelink" ).relativeUrl }/${ sale.saleId }`;
                                                        } }
                                                    >
                                                        <Icon name="cancel" color="white" /> DESCARTAR CAMBIOS
                                                    </Button>
                                                    <Button
                                                        variant="success"
                                                        disabled={ this.props.content.sales.isSavingSale }
                                                        onClick={ () => {
                                                            this.saveSale();
                                                        } }
                                                    >
                                                        { this.props.content.sales.isSavingSale ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }
                                                        { !this.props.content.sales.isSavingSale ? <Icon name="check" color="white" /> : null } CONFIRMAR CAMBIOS
                                                    </Button>
                                                </ButtonGroup> : null }
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        { saleHasCanceledItems ?
                            <div>
                                <h2 style={ { textAlign: "center" } }>Items <b>cancelados</b> de tu pedido:</h2>
                                { this.renderSaleItems( "inactive" ) }
                            </div> : null }
                    </div>
                    <GenericShopOffcanvasBooking calledFrom="salelink" />
                </Container>
            </div>
        );
    }
}
GenericSaleLink.serverFetch = contentStateData;
GenericSaleLink.contextType = WebContext;
GenericSaleLink.serverFetchType = {
    type: "data",
    modules: [
        { module: "content", serverFetch: contentStateData },
        { module: "shop", serverFetch: shopStateData }
    ]
};
const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    content: state.content,
    shop: state.shop
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext, changeContentSalesAttr }, SessionActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( GenericSaleLink ) );
