/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable object-curly-newline */
/* eslint-disable class-methods-use-this */
/* eslint-disable complexity */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Helmet } from "react-helmet";
import { Card, Row, Col, Container, Ratio, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaCaretRight } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import GenericDynamicContent from "../components/GenericDynamicContent";
import GenericShopOffcanvasBooking from "./GenericShopOffcanvasBooking";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/SessionActions";
import * as ShopActions from "../actions/ShopActions";
import { contentStateData } from "../actions/ContentActions";
import * as Pages from "../utils/Pages";
import * as DataUtils from "../utils/DataUtils";
import * as ShopUtils from "../utils/ShopUtils";
import WebContext from "../utils/WebContext";
import { apiFetchDynamicContent } from "../api";

class Shop extends React.Component {
    constructor( props ) {
        super( props );
        this.renderCategory = this.renderCategory.bind( this );
        this.renderCategoryItems = this.renderCategoryItems.bind( this );
        this.renderItemCoursePanel = this.renderItemCoursePanel.bind( this );
        this.renderCategoriesSidebarButtons = this.renderCategoriesSidebarButtons.bind( this );
        this.renderPaginatedCategoryItems = this.renderPaginatedCategoryItems.bind( this );
        this.handleScroll = this.handleScroll.bind( this );
        this.getCategory = this.getCategory.bind( this );
        this.getCategoryName = this.getCategoryName.bind( this );
        this.page = Pages.getPage( "shop" );
        let selectedCategory = null;
        selectedCategory = props.shop.categories.find( ( category ) => props.location.pathname === `${ this.page.relativeUrl }/${ category.id }` );
        const selectedBrand = props.shop.brands.find( ( brand ) => props.location.pathname === `${ this.page.relativeUrl }/brand/${ brand.shopBrandId }` );
        this.scrollTotalItemsIncrement = 50;
        const defaultCategoryId = props.shop.categories.length > 0 ? props.shop.categories[ 0 ].id : null;
        this.state = {
            categoryId: selectedCategory && selectedCategory.id ? selectedCategory.id : defaultCategoryId,
            selectedCategory: selectedCategory || null,
            brandId: selectedBrand && selectedBrand.shopBrandId ? selectedBrand.shopBrandId : null,
            selectedItem: null,
            toggleSideBar: false,
            onScrollTotalItems: 50,
            showCategoryViewMoreLink: true
        };
        this.renderArticle = this.renderArticle.bind( this );
        this.items = props.shop.items;
        this.state.selectedItem = this.items.find( ( item ) => item.relativeUrl === props.location.pathname );
        this.shopCartUrl = Pages.getPage( "shop-cart" ).relativeUrl;
        const tmpParams = new URLSearchParams( props.location.search );
        const encodedSearchText = tmpParams.get( "q" );
        this.searchText = "";
        if ( encodedSearchText ) {
            this.searchText = DataUtils.base64Decode( encodedSearchText );
            this.state.categoryId = null;
        }
        if ( this.state.brandId ) {
            this.state.categoryId = null;
        }
    }
    componentDidMount() {
        this.props.loadUIContext();
        if ( this.state.selectedItem && ( this.state.selectedItem.type === "product" || !this.state.selectedItem.type ) ) {
            apiFetchDynamicContent( "ShopItem", this.state.selectedItem.id )
                .then( result => {
                    let resultOverrides = Pages.applyShopOverides( result );
                    if ( resultOverrides.dynamicContent && resultOverrides.dynamicContent.length > 0 ) {
                        this.state.selectedItem.htmlDetails = resultOverrides.dynamicContent[ 0 ].value;
                    }
                } );
        }
        if ( this.state.brandId ) {
            this.props.getShopItems( null, null, this.state.brandId );
        } else if ( this.state.categoryId ) {
            this.props.getShopCategoryItems( this.state.categoryId );
        }
        if ( this.searchText ) {
            this.props.getShopItems( null, this.searchText, null );
        }
        window.addEventListener( "scroll", this.handleScroll );
    }
    shouldComponentUpdate( nextProps ) {
        if ( this.props.pwSession.shopAttributes.isEditingShopBooking && nextProps.pwSession.shopAttributes.isEditingShopBooking ) {
            return false;
        }
        return true;
    }
    componentWillUnmount() {
        window.removeEventListener( "scroll", this.handleScroll );
    }
    getCategoryName( categoryId ) {
        if ( categoryId === "giftcards" ) {
            return "Tarjeta Regalo";
        }
        if ( categoryId === "services" ) {
            return "Servicios";
        }
        if ( categoryId === "specialprices" ) {
            return "Special Prices";
        }
        let category = this.props.shop.categories.filter( ( item ) => categoryId === item.id )[ 0 ];
        category = Pages.applyShopOverides( category );
        return category ? category.name : "";
    }
    getCategory( categoryId ) {
        if ( categoryId === "giftcards" ) {
            return this.selectedCategory;
        }
        if ( categoryId === "services" ) {
            return this.selectedCategory;
        }
        let category = this.props.shop.categories.filter( ( item ) => categoryId === item.id )[ 0 ];
        category = Pages.applyShopOverides( category );
        return category;
    }
    static getCategoryUrl( baseurl, categoryId ) {
        return `${ baseurl }/${ categoryId }`;
    }
    handleScroll() {
        if ( window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight ) {
            this.setState( { onScrollTotalItems: this.state.onScrollTotalItems + this.scrollTotalItemsIncrement } );
        }
    }
    renderCategory() {
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        const coverDimensions = { width: 0, height: 0 };
        if ( this.props.UIContext.loaded ) {
            coverDimensions.width = this.props.UIContext.windowWidth - 30;
            if ( isLandscape ) {
                coverDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            coverDimensions.height = ( coverDimensions.width / 16 ) * 9;
        }
        if ( this.state.selectedItem ) {
            return null;
        }
        const category = this.state.categoryId ? this.getCategory( this.state.categoryId ) : null;
        const itemCoverDimensions = Object.assign( {}, coverDimensions );
        if ( category && category.aspectRatio ) {
            if ( isLandscape ) {
                itemCoverDimensions.width = 690;
            }
            itemCoverDimensions.height = ( itemCoverDimensions.width / category.aspectRatio.width ) * category.aspectRatio.height;
        }
        return (
            <div className="shop_category_container">
                { category && category.displayHeader ?
                    <div className="shop_category_header">
                        <Card className>
                            { !category.vimeoTop ?
                                <Card.Img
                                    variant="top"
                                    src={ category.videoTopPreview ? category.videoTopPreview : category.imageTop }
                                    alt={ this.page.seoDefaultAlt }
                                /> : null }
                            <Card.Body>
                                { category.vimeoTop ?
                                    <Ratio aspectRatio="16x9">
                                        <iframe title={ `vimeo-${ category.id }` } src={ `https://player.vimeo.com/video/${ category.vimeoTop }` } width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                    </Ratio>
                                    : null
                                }
                                <Card.Title>
                                    { category.title }
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{ category.text }</Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </div> : null
                }
                <div className="shop_category_items">
                    { this.renderPaginatedCategoryItems( this.renderCategoryItems() ) }
                    { this.state.showCategoryViewMoreLink ? <Button variant="link" block onClick={ () => this.setState( { onScrollTotalItems: this.state.onScrollTotalItems + this.scrollTotalItemsIncrement } ) }>{ Pages.text( this.context.language, "shop.items.loadmore" ) } ...</Button> : null }
                </div>
                { category ?
                    <Helmet>
                        { category.name ? <title>{ category.seoTitle ? category.seoTitle : category.name }</title> : null }
                        { category.name ? <meta property="og:title" content={ category.name } /> : null }
                        { category.name ? <meta name="DC.title" content={ category.name } /> : null }
                        { category.name ? <meta name="twitter:title" content={ category.name } /> : null }
                        { category.text ? <meta name="description" content={ category.text } /> : null }
                        { category.text ? <meta name="keywords" content={ category.text } /> : null }
                        { category.text ? <meta property="og:description" content={ category.text } /> : null }
                    </Helmet> : null }
            </div>
        );
    }
    renderItemCoursePanel( item ) {
        if ( !item.courseId ) {
            return null;
        }
        return (
            <div className="shop_item_course_panel">
                { item.courseDuration ?
                    <div>
                        <h5>Duración:</h5>
                        <p>{ item.courseDuration }</p>
                    </div> : null }
                <h5>Próximas fechas de curso:</h5>
                <ul>
                    { item.courseClasses.map( tmpClass => ( <li>{ tmpClass.courseClassText }</li> ) )}
                </ul>
            </div>
        );
    }
    renderPaginatedCategoryItems( argItems ) {
        const items = argItems;
        const cols = Pages.company.uiNavigationShopColumns ? Pages.company.uiNavigationShopColumns : 3;
        let colsMd = 4;
        if ( Pages.company.uiNavigationShopColumns === 2 ) {
            colsMd = 6;
        }
        const result = [];
        let colsItems = [];
        if ( this.searchText && items.length === 0 ) {
            return <div style={ { margin: 20, textAlign: "center" } }><h4>{ Pages.text( this.context.language, "shop.search.noresults" ) }: <b>{ this.searchText }</b></h4></div>;
        }
        for ( let i = 0; i < items.length; i += 1 ) {
            const colNumber = i + 1;
            colsItems.push( items[ i ] );
            if ( colNumber % cols === 0 || colNumber === items.length ) {
                // filling left cols at the end
                const modCols = colNumber % cols;
                if ( modCols > 0 ) {
                    for ( let c = 0; c < cols - modCols; c += 1 ) {
                        colsItems.push( [] );
                    }
                }
                result.push( <Row key={ `col-shop-${ i }` }> { colsItems.map( ( tmp, ci ) => <Col md={ colsMd } xs={ 12 } key={ `col-shop-${ i }-${ ci }` }> { tmp }</Col> ) } </Row> );
                colsItems = [];
            }
        }
        return result;
    }
    renderCategoryItems() {
        let isLandscape = true;
        let maxPerCategory = 6;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        if ( this.props.UIContext.loaded ) {
            maxPerCategory = this.state.onScrollTotalItems;
        }
        const coverDimensions = { width: 0, height: 0 };
        if ( this.props.UIContext.loaded ) {
            coverDimensions.width = this.props.UIContext.windowWidth - 30;
            if ( isLandscape ) {
                coverDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            coverDimensions.height = ( coverDimensions.width / 16 ) * 9;
        }
        if ( this.state.selectedItem ) {
            return null;
        }
        const categoryAndSubCategoriesIds = this.state.categoryId ? ShopUtils.getAllSubCategoriesIds( this.state.categoryId, this.props.shop.categories ) : [];
        const items = this.props.shop.currentItems.filter( ( item ) => {
            if ( this.searchText ) {
                const pattern = new RegExp( `.*${ this.searchText }.*`, "i" );
                if ( item.hidden ) {
                    return false;
                }
                return item.title.match( pattern );
            }
            if ( this.state.brandId ) {
                return item.brandId === this.state.brandId;
            }
            return !item.hidden && ( this.state.categoryId === "specialprices" || categoryAndSubCategoriesIds.includes( item.categoryId ) || this.state.categoryId === "latest" );
        } );
        if ( this.state.showCategoryViewMoreLink && ( this.state.categoryId === "latest" || items.length <= maxPerCategory ) ) {
            this.setState( { showCategoryViewMoreLink: false } );
        }
        return items.slice( 0, maxPerCategory ).map( ( argitem, index ) => {
            if ( this.state.categoryId === "latest" && index >= this.props.shop.latestMaxItems ) {
                return null;
            }
            // resellers validation
            let hideAddToCart = false;
            let hidePrice = false;
            if ( argitem.shopItemCategory && argitem.shopItemCategory.shopItemCategoryResellersOnly && !this.props.pwSession.userIsReseller ) {
                hideAddToCart = true;
                hidePrice = true;
            }
            return <GenericDynamicContent items={ [ { type: "shop_card", value: argitem, hidePrice, hideAddToCart } ] } />;
        } );
    }

    renderArticle() {
        let item = this.state.selectedItem;
        if ( !item ) {
            return null;
        }
        item = Pages.applyShopOverides( item );
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        const coverDimensions = { width: 0, height: 0 };
        if ( this.props.UIContext.loaded ) {
            coverDimensions.width = this.props.UIContext.windowWidth - 30;
            if ( isLandscape ) {
                coverDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            coverDimensions.height = ( coverDimensions.width / 16 ) * 9;
        }
        // resellers only validation
        let category = this.props.shop.categories.find( ( tmp ) => item.categoryId === tmp.id );
        let hidePrice = false;
        let hideAddToCart = false;
        if ( category && category.resellersOnly && !this.props.pwSession.userIsReseller ) {
            hidePrice = true;
            hideAddToCart = true;
        }
        if ( item.shopItemSoldOut ) {
            hidePrice = true;
            hideAddToCart = true;
        }
        return (
            <div className="pw_shop_article">
                <Row className="pw_shop_article_main_row">
                    <Col md="6">
                        { item.videoTop ?
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video width="100%" className="pw_shop_video" autoPlay loop controls muted playsinline>
                                <source src={ `${ item.videoTop }.mp4` } type="video/mp4" />
                                <source src={ `${ item.videoTop }.ogg` } type="video/ogg" />
                            </video> : null }
                        { !item.vimeoTop ?
                            <div
                                className="pw_shop_article_big_img"
                                style={ { backgroundImage: `url(${ item.videoTopPreview ? item.videoTopPreview : DataUtils.getPublicImageUrl( item.imageTop ) }` } }
                            />
                            : null }
                    </Col>
                    <Col md="6">
                        <GenericDynamicContent items={ [ { type: "shop_card", value: item, detailedView: true, hidePrice, hideAddToCart, cartButtonsTop: true } ] } />
                        { this.renderItemCoursePanel( item ) }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {item.longTextImage ?
                            <div className="pw_shop_detail_image">
                                <img style={ { width: "100%" } } src={ item.longTextImage } alt={ this.page.seoDefaultAlt } />
                            </div> : []
                        }
                        <div>{ item.longText }</div>
                    </Col>
                </Row>
                <Helmet>
                    { item.title ? <title>{ item.seoTitle ? item.seoTitle : item.title }</title> : null }
                    { item.title ? <meta property="og:title" content={ item.title } /> : null }
                    { item.title ? <meta name="DC.title" content={ item.title } /> : null }
                    { item.title ? <meta name="twitter:title" content={ item.title } /> : null }
                    { item.shortText ? <meta name="description" content={ item.shortText } /> : null }
                    { item.shortText ? <meta name="keywords" content={ item.shortText } /> : null }
                    { item.shortText ? <meta property="og:description" content={ item.shortText } /> : null }
                    { item.imageTop ?
                        <meta property="og:image" content={ DataUtils.getPublicImageUrl( item.imageTop ) } />
                        :
                        <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    }
                    <meta property="og:url" content={ `${ Pages.company.baseURL }${ item.relativeUrl }` } />
                    <link rel="canonical" href={ `${ Pages.company.baseURL }${ item.relativeUrl }` } />
                </Helmet>
                <div className="shop_cart_buttons_top">
                    <Button onClick={ () => this.props.history.goBack() } variant="pw-primary"><IoIosArrowBack /> { Pages.text( this.context.language, "shop.continue.buying" ) }</Button>
                </div>
            </div>
        );
    }
    renderCategoriesSidebarButtons() {
        const result = [];
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        let toggleValue = this.state.toggleSideBar;
        if ( !isLandscape ) {
            toggleValue = !toggleValue;
        }
        this.props.shop.categories.filter( cat => ( typeof cat.active === "undefined" || cat.active ) && cat.id === this.state.categoryId ).forEach( argCategory => {
            let allSubCategories = [];
            const category = Pages.applyShopOverides( argCategory );
            if ( category.subCategories.length > 0 ) {
                allSubCategories = ShopUtils.getAllSubCategories( category.id, this.props.shop.categories );
            } else {
                allSubCategories = category.parentCategoryId ? ShopUtils.getAllSubCategories( category.parentCategoryId, this.props.shop.categories ) : [];
            }
            allSubCategories.forEach( tmpArgCategory => {
                if ( tmpArgCategory ) {
                    const tmpCategory = Pages.applyShopOverides( tmpArgCategory );
                    result.push( <button
                        key={ `menu-${ tmpCategory.id }` }
                        type="button"
                        style={ { paddingLeft: ( tmpCategory.level - 1 ) * 10 } }
                        onClick={ () => {
                            window.scrollTo( 0, 0 );
                            this.searchText = "";
                            this.props.getShopCategoryItems( tmpCategory.id );
                            this.setState( { categoryId: tmpCategory.id, brandId: null, selectedItem: null, toggleSideBar: toggleValue, showCategoryViewMoreLink: true } );
                        } }
                        className={ `list-group-item${ tmpCategory.level === 1 ? " list-group-item-pw-primary" : "" } list-group-item-action${ tmpCategory.id === this.state.categoryId ? " selected" : "" }` }
                    >{ tmpCategory.id === category.id ? <FaCaretRight /> : null }{ tmpCategory.name }
                    </button> );
                }
            } );
        } );
        return result;
    }
    render() {
        const lang = this.context.language;
        let showSidebar = false;
        if ( this.state.selectedCategory && ( this.state.selectedCategory.level > 1 || this.state.selectedCategory.subCategories.length > 0 ) ) {
            showSidebar = true;
        }
        if ( Pages.company.uiNavigationHideShopSidebar ) {
            showSidebar = false;
        }
        return (
            <div id="shop" className={ Pages.getPageClassNames( this.page ) }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    { Pages.company.SEOShopNoIndex ? <meta name="robots" content="noindex, nofollow, noimageindex" /> : <meta name="robots" content="all" /> }
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                <Container className="pw_shop">
                    <div className={ `d-flex pw_sidebar_container_wraper${ this.state.toggleSideBar ? " toggled" : null }` }>
                        { showSidebar ?
                            <div className="pw_sidebar_wrapper">
                                <div className="list-group list-group-flush">
                                    <button type="button" className="list-group-item list-group-item-action" onClick={ () => this.setState( { toggleSideBar: !this.state.toggleSideBar } ) }><FaAngleDoubleLeft /></button>
                                    { this.renderCategoriesSidebarButtons() }
                                </div>
                            </div> : null }
                        <div className="pw_sidebar_page_content_wrapper">
                            { showSidebar ? <Button
                                variant="pw-primary"
                                size="sm"
                                className="pw_sidebar_toggle"
                                onClick={ () => {
                                    window.scrollTo( 0, 0 );
                                    this.setState( { toggleSideBar: !this.state.toggleSideBar } );
                                } }
                            ><FaAngleDoubleRight /> </Button> : null }
                            { this.renderCategory() }
                            { this.renderArticle() }
                            <GenericShopOffcanvasBooking calledFrom="shop" />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

Shop.contextType = WebContext;
Shop.serverFetch = null;
Shop.serverFetchType = {
    type: "data",
    modules: [
        { module: "shop", serverFetch: ShopActions.shopStateData, options: { excludeItems: false } },
        { module: "content", serverFetch: contentStateData }
    ]
};

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    shop: state.shop
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions, ShopActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Shop ) );
