export default {
    generic: {
        title: "Algunos de nuestros trabajos",
        subTitle: "Te presentamos solo algunos de nuestros trabajos de peluquería canina y felina ... aunque será mucho mejor si vienes a visitarnos!",
        targetRowHeight: 160,
        photos: [
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-10.jpg",
                width: 1,
                height: 1.81,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-2.jpg",
                width: 1,
                height: 1,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-3.jpg",
                width: 1,
                height: 1.40,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-4.jpg",
                width: 1,
                height: 1.32,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-16.jpg",
                width: 1.425,
                height: 1,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-11.jpg",
                width: 2.2,
                height: 1,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-5.jpg",
                width: 1.74,
                height: 1,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-6.jpg",
                width: 1,
                height: 1.06,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-8.jpg",
                width: 1.37,
                height: 1,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-17.jpg",
                width: 1,
                height: 1.21,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-12.jpg",
                width: 1,
                height: 1.16,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            },
            {
                src: "/static/peluqueria-canina-en-mallorca-photo-13.jpg",
                width: 1,
                height: 1.76,
                alt: "Pichichus: Peluqueria Canina y Felina en Palma de Mallorca"
            }
        ]
    },
    cursopeluqueriacanina: {
        title: null,
        subTitle: null,
        photos: [
            {
                src: "/static/cursopeluqueriacanina-173e12f5-c5a1-466b-8d92-0bd83041bc5f.jpg",
                width: 1,
                height: 1,
                alt: "Curso de peluqueria canina"
            },
            {
                src: "/static/cursopeluqueriacanina-11fc8c08-98d4-415b-8fff-e0fef02a34cc.jpg",
                width: 1,
                height: 1,
                alt: "Curso de peluqueria canina"
            },
            {
                src: "/static/cursopeluqueriacanina-9db96827-bbd4-4595-8df5-ba799ac34ad5.jpg",
                width: 1,
                height: 1,
                alt: "Curso de peluqueria canina"
            },
            {
                src: "/static/cursopeluqueriacanina-3ee3c698-d000-4512-a26d-32946a72f360.jpg",
                width: 1,
                height: 1,
                alt: "Curso de peluqueria canina"
            },
            {
                src: "/static/cursopeluqueriacanina-ba7e197c-e82f-4f54-8ef8-e270326c1e17.jpg",
                width: 1,
                height: 1,
                alt: "Curso de peluqueria canina"
            },
            {
                src: "/static/cursopeluqueriacanina-8f587e3b-d384-4ab0-b1b3-c2b2c99c698f.jpg",
                width: 1,
                height: 1,
                alt: "Curso de peluqueria canina"
            },
            {
                src: "/static/cursopeluqueriacanina-8b4de47b-ebf4-441b-9d82-922ef5b64fc7.jpg",
                width: 1,
                height: 1,
                alt: "Curso de peluqueria canina"
            },
            {
                src: "/static/cursopeluqueriacanina-238cb16f-8955-40a3-a7a4-abe5624cbe12.jpg",
                width: 1,
                height: 1,
                alt: "Curso de peluqueria canina"
            },
            {
                src: "/static/cursopeluqueriacanina-f108a38d-efd0-4e35-9166-a8c70b0c6ed3.jpg",
                width: 1,
                height: 1,
                alt: "Curso de peluqueria canina"
            }
        ]
    }
};
