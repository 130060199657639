import React from "react";
import Styles from "./styles/Global";
import * as Pages from "../../../utils/Pages";

const CookiesPolicy = () => (
    <div>
        <h3 style={ Styles.GenericPage.SectionTitle }>Política de Cookies</h3>
        <p>
            En esta web se utilizan cookies de terceros y propias para conseguir que tengas una mejor experiencia de navegación, puedas compartir contenido en redes sociales y para que podamos obtener estadísticas de los usuarios.
            Puedes evitar la descarga de cookies a través de la configuración de tu navegador, evitando que las cookies se almacenen en su dispositivo.
            Como propietario de este sitio web, te comunico que no utilizamos ninguna información personal procedente de cookies, tan sólo realizamos estadísticas generales de visitas que no suponen ninguna información personal.
            Es muy importante que leas la presente política de cookies y comprendas que, si continúas navegando, consideraremos que aceptas su uso.
            Según los términos incluidos en el artículo 22.2 de la Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico, si continúas navegando, estarás prestando tu consentimiento para el empleo de los referidos mecanismos.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Identidad del responsable:</h4>
        <p>
            <b>Identidad del Responsable:</b> { Pages.company.lopdFullName } - <b>NIF:</b> { Pages.company.lopdNIF } <br />
            <b>Dirección Postal:</b> { Pages.company.lopdPostalAddress } <br />
            <b>Teléfono:</b> { Pages.company.lopdPhone } - <b>Correo electrónico:</b> { Pages.company.lopdEmail } <br />
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>¿Qué son las cookies?</h4>
        <p>
            Las cookies son un conjunto de datos que un servidor deposita en el navegador del usuario para recoger la información de registro estándar de Internet y la información del comportamiento de los visitantes en un sitio web.
            Es decir, se trata de pequeños archivos de texto que quedan almacenados en el disco duro del ordenador y que sirven para identificar al usuario cuando se conecta nuevamente al sitio web.
            Su objetivo es registrar la visita del usuario y guardar cierta información.
            Su uso es común y frecuente en la web ya que permite a las páginas funcionar de manera más eficiente y conseguir una mayor personalización y análisis sobre el comportamiento del usuario.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>¿Qué tipo de cookies existen?</h4>
        <p>Las cookies utilizadas en nuestro sitio web, son de sesión y de terceros, y nos permiten almacenar y acceder a información relativa al idioma, el tipo de navegador utilizado, y otras características generales predefinidas por el usuario, así como, seguir y analizar la actividad que lleva a cabo, con el objeto de introducir mejoras y prestar nuestros servicios de una manera más eficiente y personalizada.</p>
        <p>Las cookies, en función de su permanencia, pueden dividirse en cookies de sesión o permanentes. Las que expiran cuando el usuario cierra el navegador. Las que expiran en función de cuando se cumpla el objetivo para el que sirven o bien cuando se borran manualmente.</p>
        <table border="0" width="100%" style={ { maxWidth: "1000px", border: "1px", margin: "auto" } } cellPadding="15">
            <tbody>
                <tr>
                    <td>Nombre</td>
                    <td>Tipo</td>
                    <td>Caducidad</td>
                    <td>Finalidad</td>
                    <td>Clase</td>
                </tr>
                <tr>
                    <td>__utma</td>
                    <td>De Terceros (Google Analytics)</td>
                    <td>2 años</td>
                    <td>Se usa para distinguir usuarios y sesiones.</td>
                    <td>No Exenta</td>
                </tr>
                <tr>
                    <td>__utmb</td>
                    <td>De Terceros (Google Analytics)</td>
                    <td>30 minutos</td>
                    <td>Se usa para determinar nuevas sesiones o visitas</td>
                    <td>No Exenta</td>
                </tr>
                <tr>
                    <td>__utmc</td>
                    <td>De Terceros (Google Analytics)</td>
                    <td>Al finalizar la sesión</td>
                    <td>Se configura para su uso con Urchin</td>
                    <td>No Exenta</td>
                </tr>
                <tr>
                    <td>__utmz</td>
                    <td>De Terceros (Google Analytics)</td>
                    <td>6 meses</td>
                    <td>Almacena el origen o la campaña que explica cómo el usuario ha llegado hasta la página web</td>
                    <td>No Exenta</td>
                </tr>
            </tbody>
        </table>
        <p>
            Adicionalmente, en función de su objetivo, las cookies pueden clasificarse de la siguiente forma:
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Cookies de rendimiento</h4>
        <p>
            Este tipo de Cookie recuerda sus preferencias para las herramientas que se encuentran en los servicios, por lo que no tiene que volver a configurar el servicio cada vez que usted visita.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Cookies de geo-localización</h4>
        <p>
            Estas cookies son utilizadas para averiguar en qué país se encuentra cuando se solicita un servicio. Esta cookie es totalmente anónima, y sólo se utiliza para ayudar a orientar el contenido a su ubicación.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Cookies de registro</h4>
        <p>
            Las cookies de registro se generan una vez que el usuario se ha registrado o posteriormente ha abierto su sesión, y se utilizan para identificarle en los servicios con los siguientes objetivos:
        </p>
        <p>
            Mantener al usuario identificado de forma que, si cierra un servicio, el navegador o el ordenador y en otro momento u otro día vuelve a entrar en dicho servicio, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse.
        </p>
        <p>
            Comprobar si el usuario está autorizado para acceder a ciertos servicios, por ejemplo, para participar en un concurso.
        </p>
        <p>
            Adicionalmente, algunos servicios pueden utilizar conectores con redes sociales tales como Facebook o Twitter. Cuando el usuario se registra en un servicio con credenciales de una red social, autoriza a la red social a guardar una Cookie persistente que recuerda su identidad y le garantiza acceso a los servicios hasta que expira. El usuario puede borrar esta Cookie y revocar el acceso a los servicios mediante redes sociales actualizando sus preferencias en la red social que específica.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Cookies de analíticas</h4>
        <p>
            Cada vez que un usuario visita un servicio, una herramienta de un proveedor externo genera una cookie analítica en el ordenador del usuario. Esta cookie que sólo se genera en la visita, servirá en próximas visitas para identificar de forma anónima al visitante. Los objetivos principales que se persiguen son:
            Permitir la identificación anónima de los usuarios navegantes a través de la cookie (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo.
            Identificar de forma anónima los contenidos más visitados y por lo tanto más atractivos para los usuarios Saber si el usuario que está accediendo es nuevo o repite visita.
            Importante: la cookie nunca irá asociada a ningún dato de carácter personal que pueda identificarle.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Cookies de publicidad</h4>
        <p>
            Este tipo de cookies permiten ampliar la información de los anuncios mostrados a cada usuario anónimo en los servicios. Entre otros, se almacena la duración o frecuencia de visualización de posiciones publicitarias, la interacción con las mismas, o los patrones de navegación y/o comportamientos del usuario ya que ayudan a conformar un perfil de interés publicitario. De este modo, permiten ofrecer publicidad afín a los intereses del usuario.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>¿Cómo puedo deshabilitar las cookies en mi navegador?</h4>
        <p>
            Se pueden configurar los diferentes navegadores para avisar al usuario de la recepción de cookies y, si se desea, impedir su instalación en el equipo. Asimismo, el usuario puede revisar en su navegador qué cookies tiene instaladas y cuál es el plazo de caducidad de las mismas, pudiendo eliminarlas.
        </p>
        <p>
            Para ampliar esta información consulte las instrucciones y manuales de su navegador:
        </p>
        <p>
            Para más información sobre la administración de las cookies en Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=es">https://support.google.com/chrome/answer/95647?hl=es</a> <br />
            Para más información sobre la administración de las cookies en Internet Explorer: <a href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions">http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions</a> <br />
            Para más información sobre la administración de las cookies en Mozilla Firefox: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a> <br />
            Para más información sobre la administración de las cookies en Safari: <a href="http://www.apple.com/es/privacy/use-of-cookies/">http://www.apple.com/es/privacy/use-of-cookies/</a> <br />
            Para más información sobre la administración de las cookies en Opera: <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a> <br />
            Si desea dejar de ser seguido por Google Analytics visite: <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a> <br />
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Para saber más sobre las cookies</h4>
        <p>
            Puede obtener más información sobre la publicidad online basada en el comportamiento y la privacidad online en el siguiente enlace: <a href="http://www.youronlinechoices.com/es/">http://www.youronlinechoices.com/es/</a><br />
            Protección de datos de Google Analytics: <a href="http://www.google.com/analytics/learn/privacy.html">http://www.google.com/analytics/learn/privacy.html</a><br />
            Cómo usa Google Analytics las cookies: <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs</a><br />
        </p>
    </div>
);
export default CookiesPolicy;
