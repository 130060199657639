/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Helmet } from "react-helmet";
import { Container, Media, Button, Row, Col, Form, Tab, Nav, Modal, Alert, Spinner, ButtonGroup, ButtonToolbar, Card } from "react-bootstrap";
import { injectStripe, Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IoMdCheckmark, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Icon from "./widgets/Icon";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/SessionActions";
import { contentStateData } from "../actions/ContentActions";
import ExpandCollapse from "../components/widgets/ExpandCollapse";
import * as Pages from "../utils/Pages";
import * as DataUtils from "../utils/DataUtils";
import * as ShopUtils from "../utils/ShopUtils";
import * as DataValidations from "../utils/DataValidations";
import WebContext from "../utils/WebContext";

const stripeButton = ( props ) => (
    <Button
        variant="success"
        disabled={ props.disabled || ( props.pwSession.shopAttributes && props.pwSession.shopAttributes.purchaseIsProcessing ) }
        onClick={ () => {
            let valid = true;
            const {
                userName, userEmail, userMobile, userAddress, userCity, userPostalCode, userProvince
            } = props.pwSession.shopAttributes;
            if ( props.shop.signupRequired ) {
                if ( !props.pwSession.loggedIn ) {
                    props.changeShopCartAttr( "errorMessage", "shop.validation.requiredaccount" );
                    valid = false;
                }
                if ( valid && !props.pwSession.userEmailVerified ) {
                    props.changeShopCartAttr( "errorMessage", "shop.validation.requiredemailverified" );
                    valid = false;
                }
            }
            if ( valid && ( !userName || !userEmail || !userMobile || !userAddress ) ) {
                props.changeShopCartAttr( "errorMessage", "shop.validation.empty" );
                valid = false;
            }
            if ( valid && props.shop.shipping && ( !userCity || !userPostalCode || !userProvince ) ) {
                props.changeShopCartAttr( "errorMessage", "shop.validation.empty" );
                valid = false;
            }
            if ( valid && !props.pwSession.acceptPrivacyPolicy ) {
                props.changeShopCartAttr( "errorMessage", "shop.validation.privacypolicyandterms" );
                valid = false;
            }
            if ( valid && !DataValidations.isValidEmail( userEmail ) ) {
                props.changeShopCartAttr( "errorMessage", "shop.validation.invalidemail" );
                valid = false;
            }
            if ( valid ) {
                props.processPaymentLinkRequest( { stripe: props.stripe } );
            }
        } }
    >
        { props.pwSession.shopAttributes && props.pwSession.shopAttributes.purchaseIsProcessing ? <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
        /> : null }
        { Pages.text( props.language, "shop.checkout.processpaymentlink" ) }
    </Button>
);

const InjectedStripeButton = injectStripe( connect( ( state ) => ( { pwSession: state.pwSession, shop: state.shop } ), Object.assign( {}, SessionActions ) )( stripeButton ) );

class GenericPaymentLink extends React.Component {
    constructor( props ) {
        super( props );
        this.page = Pages.getPage( "paymentlink" );
        this.renderSuccess = this.renderSuccess.bind( this );
        this.renderProcessPurchaseButton = this.renderProcessPurchaseButton.bind( this );
        this.state = { stripe: null, redsysSaveCard: false };
        this.view = "checkout";
        this.trafficFrom = null;
        this.isLoaded = false;
        const tmpParams = new URLSearchParams( props.location.search );
        const tmpParamsKeys = Array.from( tmpParams.keys() );
        this.renderNotAvailableLink = this.renderNotAvailableLink.bind( this );
        this.urlParams = {};

        for ( let i = 0; i < tmpParamsKeys.length; i += 1 ) {
            this.urlParams[ tmpParamsKeys[ i ] ] = tmpParams.get( tmpParamsKeys[ i ] );
            if ( tmpParamsKeys[ i ] === "view" ) {
                this.view = this.urlParams[ tmpParamsKeys[ i ] ];
            }
            if ( tmpParamsKeys[ i ] === "trafficFrom" ) {
                this.trafficFrom = this.urlParams[ tmpParamsKeys[ i ] ];
            }
        }
    }
    componentDidMount() {
        this.props.loadUIContext();
        if ( Pages.stripe && Pages.stripe.publicKey ) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState( { stripe: window.Stripe( Pages.stripe.publicKey ) } );
        }
    }
    validateForm() {
        let result = true;
        const {
            userName, userEmail, userMobile, userAddress, userCity, userPostalCode, userProvince
        } = this.props.pwSession.shopAttributes;
        return result;
    }
    renderSuccess() {
        let isLandscape = true;
        const lang = this.context.language;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        return (
            <div id="shop-cart" style={ { paddingTop: this.page.navBarCSSPosition === "fixed" && this.page.navBarPagePadding ? this.page.navBarPagePadding : 0 } }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                <Container>
                    <h2 className="pw_section_title">El pago se ha procesado exitosamente</h2>
                    <Alert variant="success">
                        <p>Nuestro equipo ha recibido la notificación del pago correctamente.</p>
                        <p><b>Muchas gracias</b></p>
                    </Alert>
                </Container>
            </div>
        );
    }
    renderNotAvailableLink( type ) {
        let title = "Link no disponible";
        let description = "El link al que intenta acceder no se encuentra disponible.";
        let variant = "warning";
        switch ( type ) {
            case "notexists": {
                title = "El link de pago al que intenta acceder no es válido.";
                description = "Por favor, verifique que el link es correcto o solicite un nuevo link de pago.";
                break;
            }
            case "paid": {
                title = "El link de pago al que intenta acceder ya ha sido pagado.";
                description = "Muchas gracias.";
                variant = "success";
                break;
            }
            case "inactive": {
                title = "El link de pago al que intenta acceder ya no está activo";
                description = "Por favor, solicite un nuevo link de pago.";
                break;
            }
            case "expired": {
                title = "El link de pago al que intenta acceder ha expirado";
                description = "Por favor, solicite un nuevo link de pago.";
                break;
            }
            default:
        }
        return (
            <div id="shop-cart" style={ { paddingTop: this.page.navBarCSSPosition === "fixed" && this.page.navBarPagePadding ? this.page.navBarPagePadding : 0 } }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ this.context.language } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ this.context.language } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                <Container>
                    <Alert variant={ variant }>
                        <Alert.Heading>{ title }</Alert.Heading>
                        <p>
                            { description }
                        </p>
                    </Alert>
                </Container>
            </div>
        );
    }
    renderProcessPurchaseButton() {
        if ( this.props.content.paymentLinks.current.paymentLinkPaymentMethod === "stripe" ) {
            return (
                <Elements>
                    <InjectedStripeButton
                        language={ this.context.language }
                    />
                </Elements>
            );
        }
        if ( this.props.content.paymentLinks.current.paymentLinkPaymentMethod === "redsys" ) {
            let savedCardToken = null;
            let savedCardBrand = null;
            let savedCardExpiration = null;
            if ( this.state.redsysSaveCard && this.props.content.paymentLinks.current.paymentLinkCustomer && this.props.content.paymentLinks.current.paymentLinkCustomer.customerCardTokenId ) {
                savedCardToken = this.props.content.paymentLinks.current.paymentLinkCustomer.customerCardTokenId;
                savedCardBrand = this.props.content.paymentLinks.current.paymentLinkCustomer.customerCardTokenBrand;
                savedCardExpiration = DataUtils.formatDateNice( this.props.content.paymentLinks.current.paymentLinkCustomer.customerCardTokenExpirationDatetime, "MM/YY" );
                if ( savedCardExpiration && DataUtils.isPastDateTime( this.props.content.paymentLinks.current.paymentLinkCustomer.customerCardTokenExpirationDatetime, DataUtils.now() ) ) {
                    savedCardToken = null;
                }
            }
            return (
                <form name="redsysform" id="redsysform" action="https://sis-t.redsys.es" method="POST">
                    <input type="hidden" id="Ds_SignatureVersion" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
                    <input type="hidden" id="Ds_MerchantParameters" name="Ds_MerchantParameters" value="" />
                    <input type="hidden" id="Ds_Signature" name="Ds_Signature" value="" />
                    { Pages.redsys && Pages.redsys.bizum ?
                        <Button
                            variant="success"
                            onClick={ ( e ) => {
                                e.preventDefault();
                                if ( this.validateForm() ) {
                                    this.props.processPaymentLinkRequest( null, { redsysPaymentMethod: "bizum" } );
                                }
                            } }
                        >
                            <Icon name="bizum" color="white" />
                            { Pages.text( this.context.language, "shop.checkout.processpaymentlink.bizum" ) }
                        </Button> : null }
                    <Button
                        variant={ savedCardToken ? "secondary" : "success" }
                        onClick={ ( e ) => {
                            e.preventDefault();
                            if ( this.validateForm() ) {
                                this.props.processPaymentLinkRequest( null, { redsysSaveCard: this.state.redsysSaveCard } );
                            }
                        } }
                    >
                        <Icon name="credit-card" color="white" />&nbsp;
                        { Pages.text( this.context.language, savedCardToken ? "shop.checkout.paybyanothercard" : "shop.checkout.processpaymentlink" ) }
                    </Button>
                    { savedCardToken ?
                        <Button
                            variant="success"
                            className="saved_card_btn"
                            onClick={ ( e ) => {
                                e.preventDefault();
                                if ( this.validateForm() ) {
                                    this.props.processPaymentLinkRequest( null, { redsysSaveCard: this.state.redsysSaveCard, redsysSavedCardTokenId: savedCardToken } );
                                }
                            } }
                        >
                            <Icon name={ ShopUtils.getCardIconName( "redsys", savedCardBrand ) } />&nbsp;
                            { Pages.text( this.context.language, "shop.checkout.paybysavedcard" ) }
                        </Button> : null }
                    { savedCardToken ?
                        <div className="saved_card_btn_muted_text">* La tarjeta guardada expira el { savedCardExpiration }</div>
                        : null }
                </form>
            );
        }
        return (
            <Button
                variant="pw-primary"
                onClick={ () => {
                    if ( this.validateForm() ) {
                        this.props.processPaymentLinkRequest();
                    }
                } }
            >
                { Pages.text( this.context.language, "shop.checkout.processpaymentlink" ) }
            </Button>
        );
    }
    render() {
        let isLandscape = true;
        const lang = this.context.language;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        if ( !this.props.content.paymentLinks.current ) {
            return this.renderNotAvailableLink( "notexists" );
        }
        if ( this.view === "paymentSuccess" ) {
            return this.renderSuccess();
        }
        if ( this.props.content.paymentLinks.current.paymentLinkPaid ) {
            return this.renderNotAvailableLink( "paid" );
        }
        if ( !this.props.content.paymentLinks.current.paymentLinkActive ) {
            return this.renderNotAvailableLink( "inactive" );
        }
        if ( this.props.content.paymentLinks.current.paymentLinkExpirationDatetime && DataUtils.isPastDateTime( this.props.content.paymentLinks.current.paymentLinkExpirationDatetime ) ) {
            return this.renderNotAvailableLink( "expired" );
        }
        let savedCardToken = null;
        if ( this.props.content.paymentLinks.current.paymentLinkCustomer && this.props.content.paymentLinks.current.paymentLinkCustomer.customerCardTokenId ) {
            savedCardToken = this.props.content.paymentLinks.current.paymentLinkCustomer.customerCardTokenId;
        }
        if ( !this.isLoaded && savedCardToken ) {
            this.setState( { redsysSaveCard: true } );
            this.isLoaded = true;
        }
        return (
            <StripeProvider stripe={ this.state.stripe }>
                <div id="shop-cart" className={ Pages.getPageClassNames( this.page ) }>
                    <Helmet>
                        <title>{ this.page.seoTitle }</title>
                        <meta name="description" content={ this.page.seoDescription } />
                        <meta name="keywords" content={ this.page.seoKeywords } />
                        <meta name="author" content={ this.page.seoAuthor } />
                        <meta httpEquiv="content-Language" content={ lang } />
                        <meta name="robots" content="all" />
                        <meta name="rating" content="General" />
                        <meta name="language" content={ lang } />
                        <meta name="DC.title" content={ this.page.seoTitle } />
                        <meta name="DC.description" content={ this.page.seoDescription } />
                        <meta property="og:title" content={ this.page.seoTitle } />
                        <meta property="og:description" content={ this.page.seoDescription } />
                        <meta property="og:url" content={ this.page.url } />
                        <meta property="og:type" content="website" />
                        <meta property="og:site_name" content={ Pages.company.name } />
                        <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:description" content={ this.page.seoDescription } />
                        <meta name="twitter:title" content={ this.page.seoTitle } />
                    </Helmet>
                    <Container>
                        <h2 className="pw_section_title">Pago con tarjeta</h2>
                        <div className="shop_cart_checkout_payment_method">
                            <h2 className="pw_section_subtitle">{ this.props.content.paymentLinks.current.paymentLinkTitle }</h2>
                            <div dangerouslySetInnerHTML={ { __html: this.props.content.paymentLinks.current.paymentLinkHtmlDescription } } />
                            <Card>
                                <Card.Body>
                                    <h5>{ Pages.text( this.context.language, `shop.purchase.paymentmethod.${ this.props.content.paymentLinks.current.paymentLinkPaymentMethod }` ) }</h5>
                                    <div>{ Pages.text( this.context.language, `shop.purchase.paymentmethod.${ this.props.content.paymentLinks.current.paymentLinkPaymentMethod }.description` ) }.</div>
                                    <img src={ `/static/powered_by_${ this.props.content.paymentLinks.current.paymentLinkPaymentMethod }.png` } width="125" style={ { marginTop: "10px" } } alt="Powered by" />
                                </Card.Body>
                            </Card>
                            <div className="shop_cart_totals_bottom">
                                <div className="shop_cart_totals_right_panel">
                                    <Container>
                                        <Row>
                                            <Col><b className="shop_cart_total_price">{ Pages.text( this.context.language, "shop.checkout.totalfinal" ) }:</b></Col>
                                            <Col><b className="shop_cart_total_price">{ DataUtils.formatIntegerPrice( this.props.content.paymentLinks.current.paymentLinkCurrency, this.props.content.paymentLinks.current.paymentLinkTotalAmount ) }</b></Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="shop_cart_totals_right_panel" style={ { clear: "both" } }>
                                    <Container>
                                        { Pages.redsys.redsysSaveCard ?
                                            <Row>
                                                <Col>
                                                    <div className="pws_card pws_save_card">
                                                        <Form.Check inline type="switch" id="savecard-enabled" checked={ this.state.redsysSaveCard } onChange={ ( e ) => this.setState( { redsysSaveCard: e.target.checked } ) } label={ <span><Icon name="card-security" /> Recordar tarjeta en Redsys para facilitar futuros pagos</span> } />
                                                    </div>
                                                </Col>
                                            </Row> : null }
                                        <Row>
                                            <Col>
                                                { this.props.content.paymentLinks.errorMessage ?
                                                    <Alert variant="danger">
                                                        { Pages.text( "es", this.props.content.paymentLinks.errorMessage ) }
                                                    </Alert> : null }
                                                { this.renderProcessPurchaseButton() }
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </StripeProvider>
        );
    }
}
GenericPaymentLink.serverFetch = contentStateData;
GenericPaymentLink.contextType = WebContext;
GenericPaymentLink.serverFetchType = {
    type: "data",
    modules: [
        { module: "content", serverFetch: contentStateData }
    ]
};
const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    content: state.content
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( GenericPaymentLink ) );
