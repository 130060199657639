import React from "react";
import Styles from "./styles/Global";
import * as Pages from "../../../utils/Pages";

const PrivacyPolicy = () => (
    <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
        <h3 style={ Styles.GenericPage.SectionTitle }>Protección de datos de carácter personal según la LOPD</h3>
        <p>
            { Pages.company.name }, en aplicación de la normativa vigente en materia de protección de datos de carácter
            personal, informa que los datos personales que se recogen a través de los formularios del sitio web: { Pages.company.domain }, se incluyen en los ficheros automatizados específicos de usuarios de los servicios
            de { Pages.company.name }.
            La recogida y tratamiento automatizado de los datos de carácter personal tiene como finalidad el mantenimiento de la
            relación comercial y el desempeño de tareas de información, formación, asesoramiento y otras actividades propias de { Pages.company.name }.
            Estos datos únicamente serán cedidos a aquellas entidades que sean necesarias con el único objetivo de dar
            cumplimiento a la finalidad anteriormente expuesta.<br />
            { Pages.company.name } adopta las medidas necesarias para garantizar la seguridad, integridad y
            confidencialidad de los datos conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del
            Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos
            personales y a la libre circulación de los mismos.
            El usuario podrá en cualquier momento ejercitar los derechos de acceso, oposición, rectificación y cancelación
            reconocidos en el citado Reglamento (UE). El ejercicio de estos derechos puede realizarlo el propio usuario a través de
            email a: { Pages.company.lopdEmail } { Pages.company.lopdAddress ? `o en la dirección: ${ Pages.company.lopdAddress }` : [] }.<br />
            El usuario manifiesta que todos los datos facilitados por él son ciertos y correctos, y se compromete a mantenerlos
            actualizados, comunicando los cambios a { Pages.company.lopdEmail }.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Datos del responsable del tratamiento:</h4>
        <p>
            <b>Identidad del Responsable:</b> { Pages.company.lopdFullName } - <b>NIF:</b> { Pages.company.lopdNIF } <br />
            <b>Dirección Postal:</b> { Pages.company.lopdPostalAddress } <br />
            <b>Teléfono:</b> { Pages.company.lopdPhone } - <b>Correo electrónico:</b> { Pages.company.lopdEmail } <br />
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Finalidad del tratamiento de los datos personales:</h4>
        <p>
            <strong>¿Con qué finalidad trataremos tus datos personales?</strong><br />
            En { Pages.company.name }, trataremos tus datos personales recabados a través del Sitio Web: { Pages.company.domain }, con la finalidad de responder a la información solicitada a través de los formularios dispuestos en { Pages.company.domain }<br />
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>¿Por cuánto tiempo se conservan los datos personales recabados?</h4>
        <p>
            Los datos personales proporcionados se conservarán mientras se mantenga la relación comercial o no solicites su
            supresión y durante el plazo por el cuál pudieran derivarse responsabilidades legales por los servicios prestados.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Legitimación:</h4>
        <p>
            El tratamiento de tus datos se realiza con las siguientes bases jurídicas que legitiman el mismo:<br />
            1. La solicitud de información a través de los formularios dispuestos en { Pages.company.domain }.<br />
            2. El consentimiento libre, específico, informado e inequívoco, en tanto que te informamos poniendo a tu
            disposición la presente política de privacidad, que tras la lectura de la misma, en caso de estar conforme, puedes
            aceptar mediante una declaración o una clara acción afirmativa, como el marcado de una casilla dispuesta al
            efecto.<br />
            En caso de que no nos facilites tus datos o lo hagas de forma errónea o incompleta, no podremos atender tu solicitud,
            resultando del todo imposible proporcionarte la información solicitada.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Destinatarios:</h4>
        <p>
            Los datos no se comunicarán a ningún tercero ajeno a { Pages.company.name }, salvo obligación legal.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Datos recopilados por usuarios de los servicios:</h4>
        <p>
            En los casos en que el usuario incluya ficheros con datos de carácter personal en los servidores de alojamiento
            compartido, { Pages.company.name } no se hace responsable del incumplimiento por parte del
            usuario del RGPD.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Derechos propiedad intelectual { Pages.company.name }:</h4>
        <p>
            { Pages.company.legalName } es titular de todos los derechos de autor y propiedad intelectual de los contenidos del sitio web { Pages.company.domain }.
            No se permite la reproducción, publicación y/o uso no estrictamente privado de los contenidos, totales o parciales, del
            sitio web { Pages.company.domain } sin el consentimiento previo y por escrito por parte de { Pages.company.legalName }.
        </p>
    </div>
);
export default PrivacyPolicy;
