export const CommonFonts = {
    brand: "Poiret One",
    title: "beloved-script",
    subTitle: "Poiret One",
    button: "Poiret One",
    primaryActionButton: "Poiret One",
    paragraph: "Poiret One"
};

export const CommonColors = {
    primaryBrand: "#FFC94B",
    textOnPrimaryBrand: "#000",
    secondaryBrand: "#333",
    success: "#0090FF"
};

export const CommonStyles = {
    MenuItem: {
        fontFamily: CommonFonts.button,
        fontSize: 15,
        padding: 0,
        marginLeft: 10,
        marginRight: 10,
        textDecoration: "none",
        color: "rgba(0,0,0,.5)"
    },
    MenuItemsContainer: {
        margin: 0,
        padding: 0,
        paddingLeft: 20
    },
    SectionTitle: {
        fontFamily: CommonFonts.title,
        padding: 20,
        color: "#000",
        textAlign: "center"
    },
    SectionSubTitle: {
        fontFamily: CommonFonts.subTitle,
        padding: "0 20px 20px 20px",
        fontSize: 20,
        fontWeight: "lighter",
        color: "rgba(0,0,0,.7)",
        textAlign: "center"
    },
    SectionParagraph: {
        fontFamily: CommonFonts.paragraph
    },
    SectionContentContainer: {
        padding: 0,
        textAlign: "center",
        maxWidth: "1200px",
        margin: "auto"

    }
};
const homeSvgBKG = "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23D18'/%3E%3Cstop offset='1' stop-color='%23330000'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23FA3' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23FA3' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.4'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E\")";
export default {
    Home: {
        Header: {
            padding: "30px 10px 20px 10px",
            minHeight: "calc(100vh - 180px)",
            textAlign: "center",
            borderBottomLeftRadius: "50% 10%",
            borderBottomRightRadius: "50% 10%",
            backgroundColor: "#731036",
            backgroundImage: homeSvgBKG,
            backgroundAttachment: "fixed",
            backgroundSize: "cover"
        },
        HeaderH1: {
            fontSize: "2.5em",
            fontFamily: CommonFonts.title,
            fontWeight: "500",
            color: "white",
            maxWidth: "900px",
            margin: "10px auto",
            textShadow: "-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000"
        },
        HeaderH1Portrait: {
            fontSize: "1.5em",
            fontFamily: CommonFonts.title,
            fontWeight: "500",
            color: "white",
            textShadow: "-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000"
        },
        HeaderH2: {
            fontSize: "2em",
            fontFamily: CommonFonts.subTitle,
            color: "white",
            margin: "auto",
            maxWidth: "900px",
            textShadow: "-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000"
        },
        HeaderH2Portrait: {
            fontFamily: CommonFonts.subTitle,
            color: "white",
            margin: "auto",
            fontSize: "1.2em",
            textShadow: "-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000"
        },
        HeaderCard: {
            width: "90%",
            margin: "auto",
            maxWidth: "450px",
            marginTop: "20px"
        },
        HeaderCenteredImage: {
            width: "96%",
            margin: "auto",
            maxWidth: "650px",
            marginTop: "20px"
        },
        Carousel: { backgroundColor: "#333333" },
        CarouselCaption: {
            top: "5%",
            left: 20,
            right: "55%",
            textAlign: "left"
        },
        CarouselCaptionH1: { fontFamily: CommonFonts.title, fontWeight: "500", fontSize: "3.5vw" },
        CarouselCaptionH2Slide1: {
            fontFamily: CommonFonts.title,
            fontSize: "2.5vw",
            color: "#eec7e8",
            marginBottom: "1rem"
        },
        CarouselCaptionH2Slide2: {
            fontFamily: CommonFonts.title,
            fontSize: "2.5vw",
            color: "#f7f8ab",
            marginBottom: "1rem"
        },
        CarouselCaptionH2Slide3: {
            fontFamily: CommonFonts.title,
            fontSize: "2.5vw",
            color: "#c1dcfa",
            marginBottom: "1rem"
        },
        CarouselSecondButton: {
            marginLeft: 10
        },
        CarouselPrimaryButton: {
            fontFamily: CommonFonts.button
        },
        CarouselPrimaryButtonIcon: {
            marginBottom: -5
        },
        CardDeck: {
            margin: "25px auto",
            fontFamily: CommonFonts.paragraph,
            maxWidth: "1200px"
        },
        InlineFacebookIcon: {
            display: "inline",
            width: "1.2rem",
            height: "1.2rem",
            marginBottom: 2,
            marginRight: 4
        },
        CardAddressText: {
            color: "#3689ee",
            fontWeight: "bold"
        },
        CardLink: {
            color: "#3689ee",
            padding: 0,
            margin: 0,
            textDecoration: "none"
        },
        SectionTitle: { ...CommonStyles.SectionTitle },
        SectionSubTitle: { ...CommonStyles.SectionSubTitle },
        CardFooter: {
            background: "none",
            border: "none"
        },
        SectionContentContainer: { ...CommonStyles.SectionContentContainer },
        CarouselCaptionH2: {
            fontFamily: CommonFonts.subTitle,
            fontSize: "2.5vw",
            color: "#000",
            margin: "0 auto 4px"
        },
        CarouselCaptionH3: {
            fontFamily: CommonFonts.subTitle,
            fontSize: "2.3vw",
            color: "#FFF",
            backgroundColor: "#000",
            display: "inline",
            padding: "2px 4px",
            marginBottom: 0
        },
        CarouselCaptionH4: {
            fontFamily: CommonFonts.subTitle,
            fontSize: "2vw",
            color: "#333",
            margin: 1
        },
        cardGroup: {
            justifyContent: "center",
            margin: 25,
            fontFamily: CommonFonts.paragraph
        },
        CarouselJobs: {
            height: "44%",
            textAlign: "left",
            justifyContent: "center",
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: 5,
            padding: "1vw"
        },
        CarouselJobsItem: {
            position: "relative",
            display: "inline-block",
            marginLeft: 10,
            height: "100%"
        },
        CarouselJobsFirstItem: {
            height: "100%",
            margin: "auto",
            padding: 0
        },
        CarouselJobjsImg: {
            height: "100%",
            width: "auto",
            float: "left",
            marginRight: 10
        },
        CarouselJobsItemDetails: {
            fontSize: "1.5vw",
            color: "#333"
        },
        CarouselJobsItemDetailsHighlightedText: {
            fontSize: "1.5vw",
            color: "#000",
            fontWeight: "bold"
        },
        CarouselSocialIcon: {
            width: "2.5vw",
            margin: "1vw 1.5vw 1vw 0"
        },
        poseButtonContainer: {
            display: "inline-block",
            marginTop: "1vw"
        },
        priceCards: {
            // container
        },
        priceCardTitle: {
            fontFamily: CommonFonts.title,
            fontSize: 26
        },
        priceCard: {
            width: "40%",
            margin: "auto",
            minWidth: "340px",
            maxWidth: "90%",
            textAlign: "center",
            borderColor: CommonColors.textOnPrimaryBrand
        },
        priceCardPriceCheck: {
            color: CommonColors.success
        },
        priceCardPrice: {
            fontFamily: CommonFonts.title,
            textAlign: "center"
        },
        priceCardFooter: {
            backgroundColor: CommonColors.primaryBrand,
            color: CommonColors.textOnPrimaryBrand
        }
    },
    GenericPage: {
        SectionTitle: { ...CommonStyles.SectionTitle },
        SectionSubTitle: { ...CommonStyles.SectionSubTitle },
        SectionContentContainer: {},
        SectionParagraph: { ...CommonStyles.SectionParagraph },
        ImageCircle: { margin: 10 },
        SectionCenteredFormalPane: {
            textAlign: "left",
            maxWidth: "1200px",
            margin: "auto"
        }
    },
    CookiesAcceptance: {
        AcceptCookies: {
            position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
            margin: 0,
            fontSize: "12px",
            color: "#FFF",
            backgroundColor: "rgba(0,0,0,0.8)",
            padding: "10px 10px 15px 10px"
        },
        Link: { color: "#FFF", textDecoration: "underline" }
    },
    Footer: {
        FooterContainer: {
            paddingTop: 50,
            paddingHorizontal: 50,
            paddingBottom: 10,
            fontFamily: CommonFonts.paragraph,
            backgroundColor: "#f8f9fa"
        },
        MiddleColumn: {
            textAlign: "center"
        },
        MenuItem: {
            fontFamily: CommonFonts.button,
            fontSize: 18,
            lineHeight: "34px",
            paddingBottom: 0,
            textDecoration: "none",
            color: "rgba(0,0,0,.5)"
        },
        MenuItemsContainer: {
            listStyle: "none",
            margin: 0,
            padding: 0
        },
        SectionTitle: {
            fontFamily: CommonFonts.title,
            textAlign: "center",
            paddingTop: 40,
            paddingHorizontal: 20,
            color: "rgba(0,0,0,.7)"
        },
        SectionSubTitle: {
            fontFamily: CommonFonts.title,
            textAlign: "center",
            paddingTop: 4,
            paddingHorizontal: 40,
            marginBottom: 40,
            fontSize: 20,
            fontWeight: "lighter",
            color: "rgba(0,0,0,.5)"
        },
        CopyrightFooter: {
            marginTop: 20,
            textAlign: "center",
            padding: 20,
            fontSize: 14,
            color: "rgba(0,0,0,.6)"
        },
        footerLink: {
            color: "rgba(0,0,0,.7)",
            textDecoration: "none"
        },
        ActionButtonsContainer: {
            marginBottom: 40,
            justifyContent: "center"
        },
        ActionButton: {
            margin: 10
        },
        ActionButtonIcon: {
            marginRight: 10
        },
        ContactFormContainer: {
            maxWidth: "600px",
            width: "90%",
            margin: "auto"
        },
        SuccessMessageAlert: {
            maxWidth: "700px",
            width: "90%",
            margin: "auto"
        }
    },
    NavBar: {
        ...CommonStyles,
        NavbarContainer: {
            padding: 0
        },
        NavbarBrand: {
            fontFamily: CommonFonts.brand,
            fontSize: 24,
            fontWeight: "bold",
            lineHeight: "32px"
        },
        MenuItem: {
            fontFamily: CommonFonts.button,
            fontSize: 18,
            lineHeight: "34px",
            paddingBottom: 0
        },
        Navbar: {
            backgroundColor: "#FFFFFF"
        },
        ResponsiveMenuToggle: {
            border: 0,
            fontSize: "2.5rem",
            padding: "4px 10px",
            marginTop: "4px",
            color: "#FFFFFF"
        }
    }
};
